import { useQuery, useReactiveVar } from '@apollo/client';
import { activeOrganizationVar } from '../../../../../state/state';
import { USERS_QUERY } from '../../../../../graphql/queries';
import { useMemo } from 'react';
import { Avatar, AvatarGroup, Box, Stack, SxProps, Theme } from '@mui/material';
import { UsersQuery } from '../../../../../gql/graphql';
import { Loading } from '../../../../loading/loading';
import { CheckRounded } from '@mui/icons-material';

type ThreadNodePrimitiveProps = {
  getReferenceProps: () => any;
  participantIds: string[];
  isResolved: boolean;
};

export const ThreadNodePrimitive = (props: ThreadNodePrimitiveProps) => {
  const { participantIds, isResolved } = props;

  const organization = useReactiveVar(activeOrganizationVar);

  const { data, loading } = useQuery(USERS_QUERY, { variables: { organizationId: organization.id } });

  const userMap = useMemo(() => {
    return (
      data?.users.reduce<Record<string, UsersQuery['users'][0]>>((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {}) ?? {}
    );
  }, [data?.users]);

  return (
    <Box sx={rootSx}>
      {isResolved ? (
        <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
          <CheckRounded color="action" />
        </Stack>
      ) : loading ? (
        <Loading rootProps={{ width: '100%', height: '100%' }} circularProgressProps={{ size: 16 }} />
      ) : (
        <AvatarGroup max={3}>
          {participantIds.map((participantId) => (
            <Avatar
              key={participantId}
              alt={userMap[participantId]?.name ?? 'Unknown User'}
              src={userMap[participantId]?.picture}
              sx={avatarSx}
            />
          ))}
        </AvatarGroup>
      )}
    </Box>
  );
};

const rootSx: SxProps<Theme> = {
  maxWidth: '71px',
  minWidth: '28px',
  height: '30px',
  backgroundColor: 'white',
  borderRadius: '0px 8px 8px 8px',
  border: '1px solid rgba(255, 255, 255, 0.04)',
  boxShadow: '1px 0px 4px 0px rgba(0, 0, 0, 0.50)',
  cursor: 'pointer',
};

const avatarSx: SxProps<Theme> = {
  height: 24,
  width: 24,
};
