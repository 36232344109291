import { NavBar } from '../../components/nav-bar/nav-bar';
import { Box } from '@mui/material';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { ORGANIZATIONS_QUERY } from '../../graphql/queries';
import { useCallback, useEffect, useRef, useState } from 'react';
import { activeOrganizationVar, activeWorkspaceVar, navBarOverridePropsVar } from '../../state/state';
import { SearchModal } from '../../components/search-modal/search-modal';
import { WorkspaceSettingsModal } from '../../components/workspace-settings-modal/workspace-settings-modal';
import { OrganizationSettingsModal } from '../../components/organization-settings-modal/organization-settings-modal';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { InviteUsersModal } from '../../components/invite-users-modal/invite-users-modal';
import { Loading } from '../../components/loading/loading';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { KeyboardShortcutsModal } from '../../components/keyboard-shortcuts-modal/keyboard-shortcuts-modal';

export const HomeRoute = () => {
  const navBarOverrideProps = useReactiveVar(navBarOverridePropsVar);
  const activeOrganization = useReactiveVar(activeOrganizationVar);
  const activeWorkspace = useReactiveVar(activeWorkspaceVar);

  const { organizationName, workspaceName } = useParams<{ organizationName: string; workspaceName?: string }>();

  const [isReady, setIsReady] = useState(false);

  const { trackEvent } = useTrackEvents();

  const trackedOrganizationRef = useRef<string>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const [fetchOrganizations, { loading }] = useLazyQuery(ORGANIZATIONS_QUERY);

  const resolveOrganizationAndWorkspace = useCallback(async () => {
    if (organizationName === activeOrganization?.name && workspaceName === activeWorkspace?.name) return;
    if (!organizationName) {
      return;
    }
    const response = await fetchOrganizations();
    const { organizations } = response.data;
    const organization = organizations.find((organization) => organization.name === organizationName);
    if (!organization) {
      navigate(RoutePaths.ChooseOrganization);
      return;
    }
    localStorage.setItem('workspaces', JSON.stringify(organization.workspaces.map((w) => w.id)));
    if (organization.id !== trackedOrganizationRef.current) {
      trackEvent(AppTypeEvent.LoggedIn, { organizationName: organization.name });
      trackedOrganizationRef.current = organization.id;
    }
    activeOrganizationVar(organization);

    setIsReady(true);

    if (organization.workspaces.length === 0) {
      navigate(getRoutePathFromParams(RoutePaths.CreateWorkspace, { organizationName }));
      return;
    }

    const workspace = workspaceName
      ? organization.workspaces.find((workspace) => workspace.name === workspaceName)
      : organization.workspaces[0];

    activeWorkspaceVar(workspace);

    if (location?.state?.shouldNavigate) {
      navigate(getRoutePathFromParams(RoutePaths.Workspace, { organizationName, workspaceName: workspace.name }));
    }
  }, [
    organizationName,
    activeOrganization?.name,
    workspaceName,
    activeWorkspace?.name,
    fetchOrganizations,
    location?.state?.shouldNavigate,
    navigate,
    trackEvent,
  ]);

  useEffect(() => {
    resolveOrganizationAndWorkspace().catch(() => {});
  }, [resolveOrganizationAndWorkspace]);

  return (
    <>
      <Box data-testid="home-route" sx={{ height: '100%', width: '100%' }}>
        {loading || !isReady ? (
          <Loading rootProps={{ sx: { height: '100%', width: '100%' } }} />
        ) : (
          <Box height="100%" display="flex" flexDirection="column">
            <NavBar isLoggedIn hasOrganizations {...navBarOverrideProps} />
            <Box height="calc(100% - 64px)">
              <Outlet />
            </Box>
            <OrganizationSettingsModal />
            <WorkspaceSettingsModal />
            <InviteUsersModal />
            <KeyboardShortcutsModal />
          </Box>
        )}
        <SearchModal />
      </Box>
    </>
  );
};
