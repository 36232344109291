import { SingleSourceModel } from '../SingleSourceModel';
import { Filter } from '../entities/Filter';
import { Label } from '../entities/assets/Label';
import { EntityType } from '../entities/EntityType';
import { ActiveUser, SerializedFile } from '../data/File.types';
import { ExplorerResult } from '../read-models/explorer/ExplorerItem';
import { EntityDetailsState } from '../read-models/entity-details/EntityDetails';
import { CommandError } from '../ErrorStore';
import { AnnotatorState } from '../read-models/annotator/Annotator';
import { Viewport } from '@xyflow/react';
import { AssetsState } from '../read-models/assets/Assets';

export enum Mode {
  SELECTION = 'SELECTION',
  INSERT_CONSTRUCT = 'INSERT_CONSTRUCT',
  INSERT_SCRIPT = 'INSERT_SCRIPT',
  INSERT_MOMENT = 'INSERT_MOMENT',
  INSERT_ACTION = 'INSERT_ACTION',
  INSERT_INTERFACE = 'INSERT_INTERFACE',
  INSERT_ACTOR = 'INSERT_ACTOR',
  INSERT_SPEC = 'INSERT_SPEC',
  INSERT_THREAD = 'INSERT_THREAD',
  INSERT_CAPABILITY = 'INSERT_CAPABILITY',
}

type UrlToPreview = { url: string; title?: string; favicon?: string };

export type SingleSourceStoreState = {
  activeUsersByFile: Record<string, ActiveUser[]>;
  appliedSavedFilter: Filter;
  constructToInsert: EntityType | null;
  constructToInsertUsingDrag: EntityType | null;
  constructToPanTo?: string;
  entityDetails: EntityDetailsState | null;
  explorerResult: ExplorerResult;
  filesById: Record<string, SerializedFile>;
  graph: { nodes: any[]; edges: any[] };
  isLoaded: boolean;
  labels: Label[];
  mode: Mode;
  savedFilters: Filter[];
  searchQuery: string;
  annotator: AnnotatorState | null;
  showManageLabelsModal: boolean;
  showResolvedThreads: boolean;
  showRestorePreviousVersionModal: boolean;
  showUploadFileModal?: string;
  singleSourceModel: SingleSourceModel;
  states: Record<string, unknown>;
  unsavedFilter: Filter | undefined;
  errors: CommandError[];
  showUploadLinkModal: boolean;
  urlToPreview: UrlToPreview;
  viewport?: Viewport;
  showProposeSchemaChangesModal: boolean;
  instantMockUrl: string;
  selectedGraph: string;
  variantProposal: string;
  creatingProposal: boolean;
  graphDetails: GqlGraphDetails;
  loadingVariantsProposal: boolean;
  assets: AssetsState;
  schemaOptions: string[];
  showKeyboardShortcutsModal: boolean;
  markers: Record<string, boolean>;
  runningQuery: boolean;
  seedData: object;
  showWorkspaceResetModal: boolean;
};

export type SingleSourceStoreActions = {
  setActiveUsersByFile: (fileId: string, activeUsers: ActiveUser[]) => void;
  setAppliedSavedFilter: (appliedSavedFilters: Filter) => void;
  setConstructToInsert: (construct: EntityType | null) => void;
  setConstructToInsertUsingDrag: (constructToInsertUsingDrag: EntityType) => void;
  setConstructToPanTo: (id?: string) => void;
  setEntityDetails: (entity: EntityDetailsState) => void;
  setExplorerResult: (result: ExplorerResult) => void;
  setGraph: (graph: { nodes: any[]; edges: any[] }) => void;
  setInsertActionMode: () => void;
  setInsertActorMode: () => void;
  setInsertCapabilityMode: () => void;
  setInsertConstructMode: () => void;
  setInsertInterfaceMode: () => void;
  setInsertMomentMode: () => void;
  setInsertNarrativeMode: () => void;
  setInsertSpecMode: () => void;
  setInsertThreadMode: () => void;
  setIsLoaded: (isLoaded: boolean) => void;
  setLabels: (labels: Label[]) => void;
  setAnnotator: (annotator: AnnotatorState | null) => void;
  setSavedFilters: (filter: Filter[]) => void;
  setSearchQuery: (searchQuery: string) => void;
  setSelectionMode: () => void;
  setShowManageLabelsModal: (showManageLabelsModal: boolean) => void;
  setShowResolvedThreads: (showResolvedThreads: boolean) => void;
  setShowRestorePreviousVersionModal: (showRestorePreviousVersionModal: boolean) => void;
  setShowUploadFileModal: (showUploadFileModal?: string) => void;
  setSingleSourceModel: (model: SingleSourceModel) => void;
  setStateField: (key: string, value: unknown) => void;
  setUnsavedFilter: (filter: Filter) => void;
  setErrors: (errors: CommandError[]) => void;
  setShowUploadLinkModal: (showUploadLinkModal: boolean) => void;
  setUrlToPreview: (urlToPreview: UrlToPreview) => void;
  setViewport: (viewport: Viewport) => void;
  setShowProposeSchemaChangesModal: (showProposeSchemaChangesModal: boolean) => void;
  setInstantMockUrl: (instantMockUrl: string) => void;
  setSelectedGraph: (selectedGraph: string) => void;
  setVariantProposal: (variantProposal: string) => void;
  setGraphDetails: (graphDetails: GqlGraphDetails) => void;
  setLoadingVariantsProposal: (loadingVariantsProposal: boolean) => void;
  setAssets: (assets: AssetsState) => void;
  setSchemaOptions: (schemaOptions: string[]) => void;
  setCreatingProposal: (creatingProposal: boolean) => void;
  setShowKeyboardShortcutModal: (showKeyboardShortcutsModal: boolean) => void;
  setMarkers: (params: { id: string; marker: boolean }) => void;
  setRunningQuery: (runningQuery: boolean) => void;
  setSeedData: (seedData: object) => void;
  setShowWorkspaceResetModal: (showWorkspaceResetModal: boolean) => void;
};

export type SingleSourceStore = SingleSourceStoreState & SingleSourceStoreActions;

export type InteractorResponse = { action: string; params: any } | undefined;

type Schema = {
  document: string;
  __typename: string;
};

type SchemaPublication = {
  publishedAt: string;
  schema: Schema;
  __typename: 'SchemaPublication';
};

type GraphVariant = {
  key: string;
  name: string;
  latestPublication?: SchemaPublication;
  __typename: 'GraphVariant';
};

type User = {
  name: string;
  __typename: 'User';
};

type Proposal = {
  key: string;
  displayName: string;
  status: string;
  id: string;
  createdAt: string;
  createdBy: User;
  backingVariant: GraphVariant;
  __typename: 'Proposal';
};

export type GqlGraphDetails = {
  name: string;
  variants: GraphVariant[];
  proposals: Proposal[];
  __typename: 'Graph';
};

export type GqlGraph = {
  id: string;
  name: string;
  proposals?: {
    totalCount: number;
    __typename: string;
  };
  variants?: {
    __typename: string;
  }[];
};
