import { ChangeEvent, CSSProperties, Fragment, memo, useCallback, useMemo, useState } from 'react';
import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { Overlay } from '../../constructs/overlay/overlay';
import { AddLane } from './lane/add-lane/add-lane';
import { useApplication } from 'presentation/client/src/wrappers/application-context/application-context';
import { useIntl } from 'react-intl';
import { Box, IconButton, InputBase, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { AddFrame } from './frame/add-frame/add-frame';
import { Delete } from '@mui/icons-material';
import { ScriptNodeData } from '@xspecs/single-source-model';
import css from './script-node.module.css';

export const ScriptNode = (props: NodeProps<Node<ScriptNodeData>>) => {
  const { data, id } = props;

  const { application } = useApplication();

  const [hoveredFrameIndex, setHoveredFrameIndex] = useState<number | null>(null);
  const [hoveredLaneIndex, setHoveredLaneIndex] = useState<number | null>(null);

  const onDeleteLane = useCallback(
    (index: number) => {
      application.model.scriptInteractor.deleteLane(id, index);
    },
    [application.model.scriptInteractor, id],
  );

  const onAddLane = useCallback(
    (index: number) => {
      application.model.scriptInteractor.addLane(id, index);
    },
    [application.model.scriptInteractor, id],
  );

  const onRenameLane = useCallback(
    (index: number, name: string) => {
      application.model.scriptInteractor.renameLane(id, index, name);
    },
    [application.model.scriptInteractor, id],
  );

  const onDeleteFrame = useCallback(
    (index: number) => {
      application.model.scriptInteractor.deleteFrame(id, index);
    },
    [application.model.scriptInteractor, id],
  );

  const onAddFrame = useCallback(
    (index: number) => {
      application.model.scriptInteractor.addFrame(id, index);
    },
    [application.model.scriptInteractor, id],
  );

  const resetHoveredIndexes = useCallback(() => {
    setHoveredFrameIndex(null);
    setHoveredLaneIndex(null);
  }, []);

  const onMouseEnter = useCallback(
    (frameIndex: number) => () => {
      setHoveredFrameIndex(frameIndex);
      setHoveredLaneIndex(null);
    },
    [],
  );

  const onCellMouseEnter = useCallback(
    (params: { frameIndex: number; laneIndex: number }) => () => {
      setHoveredFrameIndex(params.frameIndex);
      setHoveredLaneIndex(params.laneIndex);
    },
    [],
  );

  const height = data.height - 12;
  const width = data.width - 12;
  const frameWidths = useMemo(() => data.frames.map((frame) => frame.width), [data.frames]);

  const lanesPosition = useMemo(() => {
    const result: number[] = [];
    let sum: number = 0;

    data.lanes.forEach((_, index) => {
      if (index === 0) {
        result.push(0);
      } else {
        sum += data.lanes[index - 1].height;
        result.push(sum);
      }
    });
    return result;
  }, [data.lanes]);

  return (
    <Fragment>
      <Box width="100%" height="100%" position="relative" onMouseLeave={resetHoveredIndexes}>
        {data.hasOverlay && <Overlay />}
        <table className={css.table}>
          <Head
            scriptId={id}
            widths={frameWidths}
            hoveredFrameIndex={hoveredFrameIndex}
            onDeleteFrame={onDeleteFrame}
            onMouseEnter={onMouseEnter}
          />
          <tbody className={css.body}>
            {data.lanes.map((lane, laneIndex, lanes) => (
              <tr key={lane.id} style={{ backgroundColor: lane.color }}>
                {data.frames.map((frame, frameIndex, frames) => (
                  <Cell
                    key={id + frameIndex + laneIndex}
                    frame={frame}
                    lane={lane}
                    frameIndex={frameIndex}
                    laneIndex={laneIndex}
                    laneMutationAllowed={data.capabilities.laneMutationAllowed}
                    onAddFrame={onAddFrame}
                    height={height}
                    resetHoveredIndexes={resetHoveredIndexes}
                    lanesCount={lanes.length}
                    framesCount={frames.length}
                    width={width}
                    onAddLane={onAddLane}
                    onCellMouseEnter={onCellMouseEnter}
                  />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {lanesPosition.map((position, index, positions) => {
          const lane = data.lanes[index];
          return (
            <LaneName
              key={lane.id + '_displayName'}
              top={positions[index]}
              id={lane.id}
              laneMutationAllowed={data.capabilities.laneMutationAllowed}
              displayName={lane.displayName}
              onRenameLane={onRenameLane}
              index={index}
            />
          );
        })}
        {data.capabilities.laneMutationAllowed
          ? data.lanes.map((lane, laneIndex) => (
              <DeleteLane
                key={lane.id + '_deleteLane'}
                id={lane.id}
                top={lanesPosition[laneIndex]}
                laneHeight={lane.height}
                setHoveredFrameIndex={setHoveredFrameIndex}
                setHoveredLaneIndex={setHoveredLaneIndex}
                laneIndex={laneIndex}
                onDeleteLane={onDeleteLane}
                isVisible={hoveredLaneIndex === laneIndex}
              />
            ))
          : null}
      </Box>
      <Handles />
    </Fragment>
  );
};

// Delete Lanes
type DeleteLaneProps = {
  id: string;
  top: number;
  laneHeight: number;
  setHoveredFrameIndex: (index: number | null) => void;
  setHoveredLaneIndex: (index: number | null) => void;
  laneIndex: number;
  onDeleteLane: (index: number) => void;
  isVisible: boolean;
};
const DeleteLane = (props: DeleteLaneProps) => {
  const { id, top, setHoveredFrameIndex, setHoveredLaneIndex, laneHeight, laneIndex, onDeleteLane, isVisible } = props;

  const sx = useMemo(
    () => ({
      position: 'absolute',
      top,
      width: 80,
      height: laneHeight,
      transform: 'translateX(-75%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [laneHeight, top],
  );

  const onMouseEnter = useCallback(() => {
    setHoveredFrameIndex(null);
    setHoveredLaneIndex(laneIndex);
  }, [laneIndex, setHoveredFrameIndex, setHoveredLaneIndex]);

  const onClick = useCallback(() => onDeleteLane(laneIndex), [laneIndex, onDeleteLane]);

  const iconButtonSx = useMemo(() => ({ p: 0.25, visibility: isVisible ? 'visible' : 'hidden' }), [isVisible]);
  return (
    <Box key={id} sx={sx} onMouseEnter={onMouseEnter}>
      <IconButton size="small" sx={iconButtonSx} onClick={onClick}>
        <Delete fontSize="inherit" />
      </IconButton>
    </Box>
  );
};

// Lane Names
type LaneNameProps = {
  top: number;
  id: string;
  laneMutationAllowed: boolean;
  displayName: string;
  onRenameLane: (index: number, name: string) => void;
  index: number;
};
const LaneName = memo((props: LaneNameProps) => {
  const { top, id, laneMutationAllowed, displayName, onRenameLane, index } = props;
  const theme = useTheme();
  const { formatMessage: f } = useIntl();

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onRenameLane(index, e.target.value);
    },
    [index, onRenameLane],
  );

  const commonStyles = useMemo(
    () =>
      ({
        top: top + 8,
        left: 10,
        position: 'absolute',
        zIndex: 100,
        outline: 'none',
        backgroundColor: 'transparent',
      } as const),
    [top],
  );

  const inputStyles = useMemo(
    () =>
      ({
        zIndex: 1000,
        ...commonStyles,
        ...theme.typography.tooltip,
      } as const),
    [commonStyles, theme.typography.tooltip],
  );

  return laneMutationAllowed ? (
    <InputBase
      key={id + '_displayName_input'}
      className="nodrag"
      value={displayName}
      sx={inputStyles}
      onChange={onChange}
      placeholder={f({ id: 'untitled' })}
    />
  ) : (
    <Typography key={id + '_displayName'} sx={commonStyles} variant="tooltip">
      {displayName}
    </Typography>
  );
});
LaneName.displayName = 'LaneName';

// Cell
type CellProps = {
  frame: ScriptNodeData['frames'][0];
  lane: ScriptNodeData['lanes'][0];
  onCellMouseEnter: (params: { frameIndex: number; laneIndex: number }) => () => void;
  frameIndex: number;
  laneIndex: number;
  laneMutationAllowed: boolean;
  onAddFrame: (index: number) => void;
  onAddLane: (index: number) => void;
  height: number;
  resetHoveredIndexes: () => void;
  lanesCount: number;
  framesCount: number;
  width: number;
};
const Cell = memo((props: CellProps) => {
  const {
    frame,
    lane,
    onCellMouseEnter,
    frameIndex,
    laneIndex,
    laneMutationAllowed,
    onAddFrame,
    height,
    resetHoveredIndexes,
    lanesCount,
    framesCount,
    width,
    onAddLane,
  } = props;
  const theme = useTheme();
  const style = useMemo(
    () =>
      ({
        padding: 0,
        border: `1px solid ${theme.palette.grey[500]}`,
        width: frame.width,
        height: lane.height - 12,
        position: 'relative',
      } satisfies CSSProperties),
    [frame.width, lane.height, theme.palette.grey],
  );

  return (
    <td key={frame.id} style={style} onMouseEnter={onCellMouseEnter({ frameIndex, laneIndex })}>
      {/*ADD FRAMES*/}
      {laneIndex === 0 ? (
        <AddFrames
          onAddFrame={onAddFrame}
          frameIndex={frameIndex}
          height={height}
          framesCount={framesCount}
          resetHoveredIndexes={resetHoveredIndexes}
        />
      ) : null}
      {/*ADD LANES*/}
      {laneMutationAllowed && frameIndex === 0 ? (
        <AddLanes
          lanesCount={lanesCount}
          onAddLane={onAddLane}
          laneIndex={laneIndex}
          resetHoveredIndexes={resetHoveredIndexes}
          width={width}
        />
      ) : null}
    </td>
  );
});
Cell.displayName = 'Cell';

// Add Frames
type AddFramesProps = {
  resetHoveredIndexes: () => void;
  height: number;
  frameIndex: number;
  framesCount: number;
  onAddFrame: (index: number) => void;
};
const AddFrames = memo((props: AddFramesProps) => {
  const { resetHoveredIndexes, onAddFrame, height, frameIndex, framesCount } = props;

  const onClick = useCallback(
    (index: number) => () => {
      onAddFrame(index);
    },
    [onAddFrame],
  );

  const addFrameSx = useMemo(
    () => ({
      left: 0,
      position: 'absolute',
      transform: 'translateX(-50%)',
      height,
      top: 0,
      cursor: 'pointer',
      zIndex: 999,
    }),
    [height],
  );
  const addFrameLastSx = useMemo(
    () => ({
      right: 0,
      position: 'absolute',
      transform: 'translateX(50%)',
      height,
      top: 0,
      cursor: 'pointer',
      zIndex: 999,
    }),
    [height],
  );
  const isLast = frameIndex === framesCount - 1;
  return (
    <Fragment>
      <Box onMouseEnter={resetHoveredIndexes} onClick={onClick(frameIndex)} sx={addFrameSx}>
        <AddFrame />
      </Box>
      {isLast ? (
        <Box onClick={onClick(framesCount)} sx={addFrameLastSx}>
          <AddFrame />
        </Box>
      ) : null}
    </Fragment>
  );
});
AddFrames.displayName = 'AddFrames';

// Add Lanes
type AddLanesProps = {
  lanesCount: number;
  laneIndex: number;
  resetHoveredIndexes: () => void;
  onAddLane: (index: number) => void;
  width: number;
};
const AddLanes = memo((props: AddLanesProps) => {
  const { lanesCount, resetHoveredIndexes, onAddLane, laneIndex, width } = props;

  const onClick = useCallback(
    (index: number) => () => {
      onAddLane(index);
    },
    [onAddLane],
  );

  const addLaneSx = useMemo(
    () =>
      ({
        top: 0,
        position: 'absolute',
        transform: 'translateY(-50%)',
        width,
        cursor: 'pointer',
        zIndex: 999,
      } satisfies SxProps<Theme>),
    [width],
  );

  const lastLaneSx = useMemo(
    () =>
      ({
        bottom: 0,
        position: 'absolute',
        transform: 'translateY(50%)',
        width,
        cursor: 'pointer',
        zIndex: 999,
      } satisfies SxProps<Theme>),
    [width],
  );

  const isLastLane = laneIndex === lanesCount - 1;
  return (
    <Fragment>
      <Box onMouseEnter={resetHoveredIndexes} onClick={onClick(laneIndex)} sx={addLaneSx}>
        <AddLane />
      </Box>
      {isLastLane ? (
        <div onMouseEnter={resetHoveredIndexes} onClick={onClick(lanesCount)} style={lastLaneSx}>
          <AddLane />
        </div>
      ) : null}
    </Fragment>
  );
});
AddLanes.displayName = 'AddLanes';

type HeadProps = {
  scriptId: string;
  widths: number[];
  hoveredFrameIndex: number | null;
  onDeleteFrame: (index: number) => void;
  onMouseEnter: (frameIndex: number) => () => void;
};

const iconButtonSx = { p: 0.25 };
const Head = memo(
  (props: HeadProps) => {
    const { widths, scriptId, hoveredFrameIndex, onDeleteFrame, onMouseEnter } = props;

    return (
      <thead>
        <tr className={css.head}>
          {widths.map((width, frameIndex) => (
            <td
              key={scriptId + frameIndex + 'head_tr'}
              className={css.row}
              style={{ width: width }}
              onMouseEnter={onMouseEnter(frameIndex)}
            >
              <div className={hoveredFrameIndex === frameIndex ? 'visible' : 'hidden'}>
                <IconButton size="small" sx={iconButtonSx} onClick={() => onDeleteFrame(frameIndex)}>
                  <Delete fontSize="inherit" />
                </IconButton>
              </div>
            </td>
          ))}
        </tr>
      </thead>
    );
  },
  (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps),
);
Head.displayName = 'ScriptNodeHead';

// Handles
const Handles = memo(() => {
  return (
    <Fragment>
      <Handle id={Position.Left} type="source" position={Position.Left} />
      <Handle id={Position.Right} type="source" position={Position.Right} />
      <Handle id={Position.Top} type="source" position={Position.Top} />
      <Handle id={Position.Bottom} type="source" position={Position.Bottom} />
    </Fragment>
  );
});
Handles.displayName = 'Handles';
