import { Box, Button, Stack, SxProps, Theme, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { InstantMockConfig } from './instant-mock-config';
import { AnnotationSelect } from './annotation-select';
import { ActualQuery, ActualQueryProps } from './actual-query';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { useEffect, useMemo } from 'react';
import { GqlGraphDetails, GqlSchemaToFieldsTranslator } from '@xspecs/single-source-model';
import { logger } from '@xspecs/logger';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { runQuery } from './instant-mock-api';
import { useSnackStack } from '../../../wrappers/snack-stack-context';

type QueryViewProps = {
  queryId: string;
  noAnnotateDesign?: boolean;
  queryText: string;
  switchToSeedData: () => void;
} & Pick<ActualQueryProps, 'getViewport'>;

export const QueryView = (props: QueryViewProps) => {
  const { queryId, noAnnotateDesign, queryText, switchToSeedData, getViewport } = props;

  const { formatMessage: f } = useIntl();

  const { addToast } = useSnackStack();

  const graphDetails = useSingleSourceStore.use.graphDetails();
  const variantProposal = useSingleSourceStore.use.variantProposal();
  const setSchemaOptions = useSingleSourceStore.use.setSchemaOptions();
  const instantMockUrl = useSingleSourceStore.use.instantMockUrl();
  const selectedGraph = useSingleSourceStore.use.selectedGraph();
  const setVariantProposal = useSingleSourceStore.use.setVariantProposal();
  const creatingProposal = useSingleSourceStore.use.creatingProposal();
  const setCreatingProposal = useSingleSourceStore.use.setCreatingProposal();
  const setGraphDetails = useSingleSourceStore.use.setGraphDetails();
  const runningQuery = useSingleSourceStore.use.runningQuery();
  const hasMarkers = useSingleSourceStore.use.markers()[queryId];

  const schema = useMemo(() => {
    if (!graphDetails || !variantProposal) return;
    const proposal = graphDetails.proposals?.find((proposal) => proposal.key === variantProposal);
    const variant = graphDetails.variants?.find((variant) => variant.key === variantProposal);
    if (proposal) {
      return proposal.backingVariant.latestPublication?.schema?.document;
    }

    if (variant) {
      return variant.latestPublication?.schema?.document;
    }

    return null;
  }, [graphDetails, variantProposal]);

  const type = useMemo(() => {
    if (graphDetails?.variants?.find((variant) => variant.key === variantProposal)) return 'variant';
    if (graphDetails?.proposals?.find((proposal) => proposal.key === variantProposal)) return 'proposal';
    return 'proposal';
  }, [graphDetails, variantProposal]);

  useEffect(() => {
    if (schema) {
      const strings = [];
      try {
        strings.push(...GqlSchemaToFieldsTranslator.translate(schema));
      } catch (e) {
        logger.error('Error translating schema to fields. Potentially invalid schema');
      }
      setSchemaOptions(strings);
    } else {
      logger.warn('No schema found for query');
    }
    return () => {
      setSchemaOptions([]);
    };
  }, [schema, setSchemaOptions]);

  return (
    <Stack gap={1} flexGrow={1}>
      <InstantMockConfig />
      <AnnotationSelect queryId={queryId} noAnnotateDesign={noAnnotateDesign} />
      <Typography variant="body1">{f({ id: 'query' })}</Typography>
      <Box flexGrow={1} sx={rootSx}>
        <ActualQuery key={schema} queryId={queryId} schema={schema} value={queryText} getViewport={getViewport} />
      </Box>
      {hasMarkers ? (
        <LoadingButton
          variant="contained"
          loading={creatingProposal}
          disabled={!instantMockUrl || !selectedGraph || !variantProposal || !hasMarkers}
          loadingPosition="start"
          startIcon={<></>}
          sx={{
            textTransform: type === 'proposal' ? undefined : 'none',
          }}
          onClick={async () => {
            try {
              setCreatingProposal(true);
              const response = await axios.post(
                `${instantMockUrl}/create-or-update-schema-proposal-by-operation`,
                {
                  operation: queryText,
                  graphId: selectedGraph,
                  key: variantProposal,
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                },
              );

              const { data } = await axios.get<GqlGraphDetails>(
                `${instantMockUrl}/graphs/${selectedGraph}?withSubgraphs=true`,
              );
              setGraphDetails(data);
              const current = data.proposals.find((proposal) => proposal.key === response.data.proposal.key);
              setVariantProposal(current?.key ?? null);
              if (current?.key) {
                addToast({
                  severity: 'success',
                  duration: 10000,
                  children: (
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Typography>{f({ id: 'proposal-created' })}</Typography>
                      <Button
                        size="small"
                        variant="text"
                        color="success"
                        onClick={() => {
                          window.open(getProposalLink(current.key), '_blank');
                        }}
                      >
                        {f({ id: 'view-proposal' })}
                      </Button>
                    </Stack>
                  ),
                });
              }
            } catch (e) {
              logger.error(e);
              addToast({ severity: 'error', message: f({ id: 'error-creating-proposal' }) });
            } finally {
              setCreatingProposal(false);
            }
          }}
        >
          {f({ id: type === 'proposal' ? 'update-proposal' : 'create-instant-mock' })}
        </LoadingButton>
      ) : (
        <LoadingButton
          variant="contained"
          disabled={hasMarkers || !instantMockUrl || !selectedGraph || !variantProposal}
          loading={runningQuery}
          onClick={async () => {
            await runQuery(queryText, variantProposal);
            switchToSeedData();
          }}
          loadingPosition="start"
          startIcon={<></>}
        >
          {f({ id: 'run-query' })}
        </LoadingButton>
      )}
    </Stack>
  );
};

const rootSx: SxProps<Theme> = {
  backgroundColor: 'background.paper',
  border: '1px solid #E0E0E0',
  borderRadius: 1,
  flexGrow: 1,
  overflow: 'visible',
};

export const getProposalLink = (key: string) => {
  const [graphId, variantName] = key.split('@');
  return `https://studio.apollographql.com/graph/${graphId}/proposal/${variantName}`;
};
