import { FormattedNumber, useIntl } from 'react-intl';
import { Divider, Stack, Typography } from '@mui/material';
import { CustomerPortalButton } from './billing-history/customer-portal-button';
import { BillingCurrentPlan } from './current-plan/billing-current-plan';
import { usePlans } from '../../../hooks/use-plans';
import { activeOrganizationVar } from '../../../state/state';
import { useReactiveVar } from '@apollo/client';
import { Loading } from '../../loading/loading';

export const Billing = () => {
  const { formatMessage: f } = useIntl();

  const organization = useReactiveVar(activeOrganizationVar);

  const { currentPlan, loading } = usePlans();

  if (loading) {
    return <Loading rootProps={{ width: '100%', height: '100%' }} circularProgressProps={{ size: 22 }} />;
  }

  return (
    <Stack id="billing" alignItems="flex-start" gap={2} width="100%">
      <Stack gap={1.5} alignItems="flex-start">
        <Typography variant="h4">{f({ id: 'billing' })}</Typography>
        <Typography variant="body1">{f({ id: 'manage-org-billing' })}</Typography>
        {organization?.numberOfSeats ? (
          <Stack>
            <Typography variant="caption" color="text.primary"></Typography>
            <FormattedNumber
              value={currentPlan?.price}
              style="currency"
              currency={currentPlan?.currency}
              maximumFractionDigits={0}
            >
              {(value) => (
                <Stack width="100%" direction="row" alignItems="center" gap={0.5}>
                  <Typography variant="caption">
                    {f({ id: 'n-users' }, { n: organization?.numberOfSeats ?? 0 }) + ' @'}
                  </Typography>
                  <Stack width="100%" direction="row" gap={0.5} alignItems="center" whiteSpace="nowrap">
                    <Typography variant="caption">{value}</Typography>
                    <Typography variant="caption">
                      {f({ id: 'per-user-per-period' }, { period: currentPlan?.priceInterval })}
                    </Typography>
                  </Stack>
                </Stack>
              )}
            </FormattedNumber>
          </Stack>
        ) : null}
        <CustomerPortalButton />
      </Stack>
      <Divider />
      <BillingCurrentPlan />
    </Stack>
  );
};
