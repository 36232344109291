import { Alert, MenuItem, Select, SelectChangeEvent, SxProps, Theme } from '@mui/material';
import { Box } from '@mui/system';
import { useIntl } from 'react-intl';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMutation, useReactiveVar } from '@apollo/client';
import { activeOrganizationVar } from '../../../../state/state';
import { ContentSharingScope } from '../../../../gql/graphql';
import { useState } from 'react';
import { UPDATE_CONTENT_SHARING_SCOPE_MUTATION } from '../../../../graphql/mutations';
import { useSnackStack } from '../../../../wrappers/snack-stack-context';

export const OrganizationSettingsModalContentSharing = () => {
  const activeOrganization = useReactiveVar(activeOrganizationVar);
  const [updateContentSharingScope] = useMutation(UPDATE_CONTENT_SHARING_SCOPE_MUTATION);

  const [localScope, setLocalScope] = useState(activeOrganization.contentSharingScope);

  const { addToast } = useSnackStack();

  const handleRoleChange = async (e: SelectChangeEvent<ContentSharingScope>) => {
    const newScope = e.target.value as ContentSharingScope;

    const response = await updateContentSharingScope({
      variables: {
        args: {
          organizationId: activeOrganization.id,
          scope: newScope,
        },
      },
    });

    if (response.data.updateContentSharingScope.error) {
      addToast({ message: f({ id: 'organization-content-sharing-scope-update-error' }), severity: 'error' });
    } else {
      setLocalScope(newScope);
      addToast({
        message: f({ id: 'organization-content-sharing-scope-update-success' }, { scope: newScope }),
        severity: 'success',
      });
    }
  };

  const { formatMessage: f } = useIntl();

  const info: Record<ContentSharingScope, string> = {
    [ContentSharingScope.Organization]: f({ id: 'organization-users-see-link-details' }),
    [ContentSharingScope.Anyone]: f({ id: 'anyone-can-see-link-details' }),
  };

  const alertSx: SxProps<Theme> = (theme) => ({
    padding: theme.spacing(0),
    marginTop: theme.spacing(1),
    color: theme.palette.info.dark,
    background: 'none',
    '& .MuiAlert-icon': {
      marginRight: theme.spacing(0.5),
      padding: theme.spacing(0),
    },
    '& .MuiAlert-message': {
      padding: theme.spacing(0),
    },
  });

  return (
    <Box>
      <Select
        value={localScope}
        onChange={handleRoleChange}
        size="small"
        sx={{ minWidth: '100px', width: '100%', mt: 1 }}
      >
        {Object.keys(ContentSharingScope).map((scope) => (
          <MenuItem
            data-testid={`menuItem_scope-${scope}`}
            key={scope}
            value={ContentSharingScope[scope]}
            sx={{ minWidth: '100px' }}
          >
            {scope}
          </MenuItem>
        ))}
      </Select>
      <Alert sx={alertSx} icon={<InfoOutlinedIcon fontSize="small" />} severity="info">
        {info[localScope]}
      </Alert>
    </Box>
  );
};
