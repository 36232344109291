import {
  Background,
  Connection,
  ConnectionMode,
  EdgeChange,
  FitViewOptions,
  Node,
  NodeChange,
  NodeMouseHandler,
  OnConnect,
  OnNodeDrag,
  PanOnScrollMode,
  ReactFlow,
  ReactFlowProvider,
  SelectionMode,
  SnapGrid,
  useOnSelectionChange,
  UseOnSelectionChangeOptions,
  useOnViewportChange,
  useReactFlow,
  useStoreApi,
  XYPosition,
} from '@xyflow/react';
import { FpsView } from 'react-fps';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { Box, ClickAwayListener, Divider } from '@mui/material';
import {
  DragEvent,
  DragEventHandler,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Cursors } from './cursors';
import { useHotkeys, useWindowEvent } from '@mantine/hooks';
import { activeOrganizationVar, activeWorkspaceVar } from '../../../state/state';
import {
  CreateUploadCommand,
  DEBUG_CONFIG,
  EntityType,
  InteractorResponse,
  Mode,
  Status,
  UploadType,
} from '@xspecs/single-source-model';
import { CanvasControls } from './controls/canvas-controls';
import { useAuth } from '../../../auth';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { useSingleSourceModel } from '../../../hooks/use-single-source-model';
import { useSnackStack } from '../../../wrappers/snack-stack-context';
import { ManageLabelsModal } from '../../labels/manage-labels-modal/manage-labels-modal';
import { useApplication } from '../../../wrappers/application-context/application-context';
import { LabelSaveParams } from '../../labels/labels-list/labels-list-item/labels-list-item';
import { RestorePreviousVersionModal } from '../restore-previous-version-modal/restore-previous-version-modal';
import { UploadLinkModal } from '../upload-link-modal/upload-link-modal';
import { SingleSourceModelToolbar } from '../toolbar';
import { sid } from '@xspecs/short-id';
import { useCanvasCenter } from '../../../hooks/use-canvas-center';
import { FILE_UPLOAD_URLS_QUERY, LINK_PREVIEW_DETAILS_QUERY } from 'presentation/client/src/graphql/queries';
import { useIntl } from 'react-intl';
import { edgeTypes, nodeTypes } from './single-source-model-canvas.node-types';
import { logger } from '@xspecs/logger';
import { Annotator } from './annotator/annotator';
import { useImageDimensions } from './hooks/use-image-dimensions';
import { resizeImage } from '../../../utils/resizeImage';
import { WorkspaceResetStateModal } from '../../workspace-reset-state-modal/workspace-reset-state-modal';

const fitViewOptions: FitViewOptions = { padding: 0.5, maxZoom: 1, duration: 600 };

const _SingleSourceModelCanvas = () => {
  const setStoreViewport = useSingleSourceStore.use.setViewport();
  const { screenToFlowPosition, fitView, zoomIn, zoomOut, zoomTo, setViewport } = useReactFlow();
  useOnViewportChange({
    onEnd: setStoreViewport,
  });
  const { application } = useApplication();
  const { user } = useAuth();
  const singleSourceModelId = useReactiveVar(activeOrganizationVar)?.singleSourceModel.id;
  const workspace = useReactiveVar(activeWorkspaceVar);
  const model = useSingleSourceModel();
  const { nodes, edges } = useSingleSourceStore.use.graph();
  if (DEBUG_CONFIG.nodesAndEdges) logger.log('useSingleSourceGraph - nodes and edges', nodes, edges);
  const isFittedForWorkspace = useRef<string>();
  const getCanvasCenter = useCanvasCenter();
  const [getLinkPreview] = useLazyQuery(LINK_PREVIEW_DETAILS_QUERY, { fetchPolicy: 'no-cache' });
  const [getPreSignedUrl] = useLazyQuery(FILE_UPLOAD_URLS_QUERY, { fetchPolicy: 'no-cache' });
  const { formatMessage: f } = useIntl();
  const store = useStoreApi();
  const { getImageDimensions } = useImageDimensions();
  const { addToast, removeToast } = useSnackStack();

  const options: UseOnSelectionChangeOptions = useMemo(
    () => ({
      onChange: (params) => {
        if (params.nodes.length > 1) store.setState({ nodesSelectionActive: true });
      },
    }),
    [store],
  );
  useOnSelectionChange(options);

  const mode = useSingleSourceStore.use.mode();
  const showUploadLinkModal = useSingleSourceStore.use.showUploadLinkModal();
  const constructToInsert = useSingleSourceStore.use.constructToInsert();
  const constructToPanTo = useSingleSourceStore.use.constructToPanTo();
  const setConstructToPanTo = useSingleSourceStore.use.setConstructToPanTo();
  const _isLoaded = useSingleSourceStore.use.isLoaded();
  const file = useSingleSourceStore.use.filesById()[singleSourceModelId];
  const showManageLabelsModal = useSingleSourceStore.use.showManageLabelsModal();
  const setShowManageLabelsModal = useSingleSourceStore.use.setShowManageLabelsModal();
  const labels = useSingleSourceStore.use.labels();
  const setShowUploadLinkModal = useSingleSourceStore.use.setShowUploadLinkModal();
  const setShowWorkspaceResetModal = useSingleSourceStore.use.setShowWorkspaceResetModal();
  const showWorkspaceResetModal = useSingleSourceStore.use.showWorkspaceResetModal();
  const storeViewport = useSingleSourceStore.use.viewport();

  const [isModPressed, setIsModPressed] = useState(false);
  const firstLoad = useRef(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey || event.ctrlKey) {
        setIsModPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (!event.metaKey && !event.ctrlKey) {
        setIsModPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  // TODO Osama fix this please. I made a hack beause it's failing on workspace switch
  if (!file) window.location.reload();
  const isLoaded = _isLoaded && file && file.status === Status.Synced;

  const onDragOver = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const respondTo = useCallback(
    (interactorResponse: InteractorResponse) => {
      if (!interactorResponse) return;
      const { action, params } = interactorResponse;
      switch (action) {
        case 'fitView':
          fitView(params);
          break;
        case 'snackbar':
          addToast(params);
          break;
        case 'snackbars':
          if (Array.isArray(params)) params.forEach((toast) => addToast(toast));
          break;
        case 'uploadFiles':
          return params;
      }
    },
    [addToast, fitView],
  );

  const handleFileDrop = useCallback(
    async (position: XYPosition, event: DragEvent<Element>) => {
      const file = event.dataTransfer.files[0];

      if (!file) return;

      const fileType = file.type.startsWith('image') ? UploadType.Image : UploadType.File;
      const assetId = sid();

      try {
        addToast({ key: assetId, message: f({ id: 'uploading-file' }), duration: 0 });
        const { data } = await getPreSignedUrl({ variables: { parentId: assetId } });

        if (!data?.signedMediaUploadUrl) {
          addToast({
            message: f({ id: 'failed-to-get-signed-media-upload-url' }),
            severity: 'error',
          });
          return;
        }
        const uploadFileResult = await fetch(data.signedMediaUploadUrl.putUrl, {
          method: 'PUT',
          headers: { 'Content-Type': file.type || 'application/octet-stream' },
          body: file,
        });
        if (!uploadFileResult.ok) {
          addToast({
            message: f({ id: 'failed-to-upload-file' }),
            severity: 'error',
          });
          return;
        }

        let imgDimensions;
        if (fileType === UploadType.Image) {
          let originalDimensions;

          try {
            originalDimensions = await getImageDimensions(file);
          } catch (error) {
            originalDimensions = {};
          } finally {
            imgDimensions = resizeImage(originalDimensions);
          }
        }

        model.interactor.onDropFiles(position, file, data.signedMediaUploadUrl.getUrl, assetId, imgDimensions);
      } finally {
        removeToast(assetId);
      }
    },
    [addToast, f, getImageDimensions, getPreSignedUrl, model.interactor, removeToast],
  );

  const onDrop = useCallback<DragEventHandler>(
    async (event) => {
      event.preventDefault();
      const position = screenToFlowPosition({ x: event.clientX, y: event.clientY });
      if (event.dataTransfer.files.length > 0) {
        return await handleFileDrop(position, event);
      }
      const typeData = event.dataTransfer.getData('application/reactflow');
      respondTo(model.interactor.onDrop(position, typeData, user.sub));
    },
    [screenToFlowPosition, respondTo, model.interactor, user.sub, handleFileDrop],
  );

  const onMouseUp = useCallback(
    (event: MouseEvent) =>
      respondTo(model.interactor.onMouseUp(screenToFlowPosition({ x: event.clientX, y: event.clientY }), user.sub)),
    [respondTo, model.interactor, screenToFlowPosition, user.sub],
  );

  const onNodesChange = useCallback(
    (nodeChanges: NodeChange[]) => model.interactor.onNodeChanges(nodeChanges),
    [model.interactor],
  );

  const onNodeClick = useCallback<NodeMouseHandler>(
    (event, node) =>
      model.interactor.onNodeClick(
        { x: event.clientX, y: event.clientY },
        screenToFlowPosition({ x: event.clientX, y: event.clientY }),
        node.id,
        user.sub,
      ),
    [model.interactor, screenToFlowPosition, user.sub],
  );

  const handleDragEvent = useCallback(
    (
      handler: (coords: XYPosition, flowCoords: XYPosition, nodes: Node[], params: { isModPressed: boolean }) => void,
      event: MouseEvent,
      nodes: Node[],
      params: { isModPressed: boolean },
    ) => {
      const coords = { x: event.clientX, y: event.clientY };
      handler.bind(model.interactor)(coords, screenToFlowPosition(coords), nodes, params);
    },
    [model.interactor, screenToFlowPosition],
  );

  const onNodeDragStart = useCallback<OnNodeDrag>(
    (event, node: Node, nodes: Node[]) => {
      model.interactor.onNodeDragStart(
        { x: event.clientX, y: event.clientY },
        screenToFlowPosition({ x: event.clientX, y: event.clientY }),
        nodes,
      );
    },
    [model.interactor, screenToFlowPosition],
  );

  const onNodeDrag = useCallback<OnNodeDrag>(
    (e, node, nodes) => handleDragEvent(model.interactor.onNodeDrag, e, nodes, { isModPressed }),
    [handleDragEvent, model.interactor.onNodeDrag, isModPressed],
  );

  const onNodeDragStop = useCallback<OnNodeDrag>(
    (e, node, nodes) => handleDragEvent(model.interactor.onNodeDragStop, e, nodes, { isModPressed }),
    [handleDragEvent, model.interactor.onNodeDragStop, isModPressed],
  );

  const onMouseMove = useCallback<MouseEventHandler>(
    (e) => handleDragEvent(model.interactor.onMouseMove, e, [], { isModPressed }),
    [handleDragEvent, model.interactor.onMouseMove, isModPressed],
  );

  useWindowEvent('dragover', (e) =>
    handleDragEvent(model.interactor.onDragOver, e as unknown as MouseEvent, [], { isModPressed }),
  );

  const onMouseLeave = useCallback(() => model.interactor.onMouseLeave(), [model.interactor]);

  const onEdgesChange = useCallback(
    (edgeChanges: EdgeChange[]) => model.interactor.onEdgesChange(edgeChanges),
    [model.interactor],
  );

  const onConnect = useCallback<OnConnect>(
    (connection: Connection) => respondTo(model.interactor.onConnect(connection)),
    [model.interactor, respondTo],
  );

  const onUpload = useCallback(
    async (url: string) => {
      const fileType = getFileType(url);
      if (!fileType) return;
      const assetId = sid();
      const attachmentId = sid();
      const { data } = await getLinkPreview({ variables: { url } });
      const center = getCanvasCenter();

      let imgDimensions;
      if (fileType === UploadType.Image) {
        let originalDimensions;

        try {
          originalDimensions = await getImageDimensions(url);
        } catch (error) {
          originalDimensions = {};
        } finally {
          imgDimensions = resizeImage(originalDimensions);
        }
      }

      const favicon =
        data?.linkPreview.favicon ?? fileType !== UploadType.Image ? new URL(url).origin + '/favicon.ico' : null;
      application.model.messageBus.send(CreateUploadCommand, {
        assetId,
        id: attachmentId,
        name: url,
        position: { x: center.x, y: center.y },
        type: fileType,
        url,
        metadata: {
          title: data?.linkPreview.title || url,
          imageUrl: data?.linkPreview.imageUrl,
          favicon,
          ...(imgDimensions ?? {}),
        },
      });
    },
    [application.model.messageBus, getCanvasCenter, getImageDimensions, getLinkPreview],
  );

  useEffect(() => {
    if (constructToPanTo && !firstLoad.current) {
      firstLoad.current = true;
      setTimeout(() => {
        const entity = model.entities.get(constructToPanTo);
        let padding = 8;
        if (entity.type === EntityType.NarrativeScript) padding = 0.5;
        if (entity.type === EntityType.Thread) padding = 25;
        fitView({ nodes: [{ id: constructToPanTo }], duration: 500, padding: padding });
        setConstructToPanTo(undefined);
      }, 0);
    }
  }, [constructToPanTo, fitView, setConstructToPanTo, model.entities]);

  const cursor = useMemo(() => {
    function getNodeIcon(mode: Mode) {
      switch (mode) {
        case Mode.INSERT_SCRIPT:
          return `url("/script-cursor.svg"), auto`;
        case Mode.INSERT_CAPABILITY:
          return `url("/construct-capability.svg"), auto`;
        case Mode.INSERT_ACTION:
          return `url("/construct-action.svg"), auto`;
        case Mode.INSERT_INTERFACE:
          return `url("/construct-interface.svg"), auto`;
        case Mode.INSERT_MOMENT:
          return `url("/construct-moment.svg"), auto`;
        case Mode.INSERT_ACTOR:
          return `url("/attachment-actor.svg"), auto`;
        case Mode.INSERT_SPEC:
          return `url("/attachment-spec.svg"), auto`;
        case Mode.INSERT_THREAD:
          return `url("/thread.svg"), auto`;
      }
      return `url("/sticky-cursor.svg"), auto`;
    }
    if (
      constructToInsert ||
      mode === Mode.INSERT_SCRIPT ||
      mode === Mode.INSERT_CAPABILITY ||
      mode === Mode.INSERT_ACTION ||
      mode === Mode.INSERT_INTERFACE ||
      mode === Mode.INSERT_MOMENT ||
      mode === Mode.INSERT_ACTOR ||
      mode === Mode.INSERT_SPEC ||
      mode === Mode.INSERT_THREAD
    )
      return getNodeIcon(mode);

    return `url("/mouse-pointer.svg"), auto`;
  }, [mode, constructToInsert]);

  const resetZoom = useCallback(() => {
    zoomTo(1);
  }, [zoomTo]);

  // ==================== Hotkeys ====================
  useHotkeys([
    ['mod+z', () => model.interactor.undo()],
    ['mod+shift+z', () => model.interactor.redo()],
    ['mod+x', () => model.interactor.onCut()],
    ['mod+c', () => model.interactor.onCopy()],
    ['mod+v', () => model.interactor.onPaste()],
    ['mod+d', () => model.interactor.onDuplicate()],
    ['Escape', () => model.interactor.onEscape()],
    ['Delete', () => model.interactor.onDelete()],
    ['Backspace', () => model.interactor.onDelete()],
    ['mod+a', () => model.interactor.onSelectAll()],
    ['mod+shift+a', () => model.interactor.onDeSelectAll()],
    ['mod+ctrl+alt+shift+t', () => model.interactor.onTestCode()],
    ['mod+0', () => resetZoom()],
  ]);
  useWindowEvent('keydown', (event) => {
    const increase = event.key === '+' || event.key === '=';
    const decrease = event.key === '-' || event.key === '_';
    if (
      (event.ctrlKey == true || event.metaKey == true) &&
      (event.key === '+' || event.key === '-' || event.key === '=' || event.key === '_')
    ) {
      event.preventDefault();
      if (increase) zoomIn();
      if (decrease) zoomOut();
      return;
    }
    if (event.altKey) {
      if (event.code === 'Digit1' || event.code === 'NumPad1') return fitView(fitViewOptions);
      if (event.code === 'Digit2' || event.code === 'NumPad2') {
        const selectedNodes = nodes.filter((node: Node) => node.selected);
        if (selectedNodes.length > 0) fitView({ nodes: selectedNodes, ...fitViewOptions });
      }
    }
  });

  useEffect(() => {
    if (!workspace) return;
    if (isLoaded && isFittedForWorkspace.current !== workspace.id) {
      setTimeout(() => {
        isFittedForWorkspace.current = workspace.id;
        // if (storeViewport) {
        //   setViewport(storeViewport, fitViewOptions);
        // } else {
        fitView(fitViewOptions);
        // }
      }, 0);
    }
  }, [fitView, isLoaded, setViewport, storeViewport, workspace]);

  const labelsListItemProps = useMemo(
    () => ({
      onSave: (params: LabelSaveParams) => {
        params.isNew
          ? application.model.labelsInteractor.createNew(params)
          : application.model.labelsInteractor.update(params);
      },
      onConfirmDelete: (id: string) => application.model.labelsInteractor.delete(id),
    }),
    [application.model.labelsInteractor],
  );

  const onConnectStart = useCallback((event, params) => model.interactor.onConnectStart(params), [model.interactor]);
  const onSelectionChange = useCallback(
    (selection) => model.interactor.onSelectionChange(selection),
    [model.interactor],
  );
  const onSelectionEnd = useCallback((event) => model.interactor.onSelectionEnd(event), [model.interactor]);
  const onSelectionStart = useCallback((event) => model.interactor.onSelectionStart(event), [model.interactor]);

  const rootSx = useMemo(
    () => ({ flexGrow: 1, '.react-flow__pane': { cursor }, '.react-flow__node': { cursor } }),
    [cursor],
  );

  const onClickAway = useCallback(() => model.interactor.onDeSelectAll(), [model.interactor]);

  useEffect(() => {
    return () => {
      model.interactor.onDeSelectAll();
    };
  }, [model.interactor]);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box sx={rootSx}>
        <ReactFlow
          {...reactFlowConfig}
          id={singleSourceModelId}
          nodes={nodes}
          onConnect={onConnect}
          onConnectStart={onConnectStart}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onEdgesChange={onEdgesChange}
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onNodeClick={onNodeClick}
          onNodeDrag={onNodeDrag}
          onNodeDragStart={onNodeDragStart}
          onNodeDragStop={onNodeDragStop}
          onNodesChange={onNodesChange}
          onSelectionChange={onSelectionChange}
          onSelectionEnd={onSelectionEnd}
          onSelectionStart={onSelectionStart}
          onlyRenderVisibleElements={false}
          panOnDrag={false}
          panOnScroll={isMac}
          panOnScrollMode={PanOnScrollMode.Free}
          proOptions={proOptions}
          selectNodesOnDrag={true}
          selectionMode={SelectionMode.Full}
          selectionOnDrag={true}
          zoomOnDoubleClick={false}
          zoomOnPinch={true}
          zoomOnScroll={!isMac}
          snapToGrid={true}
          snapGrid={snapGrid}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          edges={edges}
        >
          <Cursors />
          <CanvasControls />
          <Background />
          {/*<Background style={{ backgroundColor: '#555' }} color="#fff" />*/}
        </ReactFlow>
        <ManageLabelsModal
          isOpen={showManageLabelsModal}
          onClose={() => setShowManageLabelsModal(false)}
          labels={labels}
          labelsListItemProps={labelsListItemProps}
        />
        <RestorePreviousVersionModal />
        <UploadLinkModal
          isOpen={showUploadLinkModal}
          onClose={() => setShowUploadLinkModal(false)}
          onUpload={onUpload}
        />
        <WorkspaceResetStateModal isOpen={showWorkspaceResetModal} onClose={() => setShowWorkspaceResetModal(false)} />
      </Box>
    </ClickAwayListener>
  );
};

export const SingleSourceModelCanvas = () => {
  const annotator = useSingleSourceStore.use.annotator();

  return (
    <>
      {DEBUG_CONFIG.fps ? <FpsView /> : null}
      {/* Important to keep outside reactflow provider to ensure that the flows' contexts don't mix */}
      {annotator?.showAnnotatorView ? (
        <Annotator annotator={annotator} />
      ) : (
        <ReactFlowProvider>
          <SingleSourceModelToolbar />
          <Divider flexItem />
          <_SingleSourceModelCanvas />
        </ReactFlowProvider>
      )}
    </>
  );
};

function getFileType(url: string): UploadType | undefined {
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg', '.webp', '.tiff'];
  const fileExtensions = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.txt', '.csv', '.json'];
  const lowerCaseUrl = url.toLowerCase();

  for (const ext of imageExtensions) {
    if (lowerCaseUrl.includes(ext)) {
      return UploadType.Image;
    }
  }

  for (const ext of fileExtensions) {
    if (lowerCaseUrl.includes(ext)) {
      return UploadType.File;
    }
  }

  return UploadType.File;
}

const isMac = navigator.userAgent.indexOf('Mac OS X') != -1;
const proOptions = { account: 'paid-pro', hideAttribution: true };
const snapGrid: SnapGrid = [0.01, 0.01];
const defaultEdgeOptions = { type: 'default' };
const deleteKeyCode = ['Backspace', 'Delete'];
const multiSelectionKeyCode = ['Shift', 'Meta'];
export const reactFlowConfig = {
  connectionMode: ConnectionMode.Loose,
  defaultEdgeOptions: defaultEdgeOptions,
  deleteKeyCode: deleteKeyCode,
  elementsSelectable: true,
  maxZoom: 8,
  minZoom: 0,
  multiSelectionKeyCode: multiSelectionKeyCode,
  nodeDragThreshold: 1,
  onlyRenderVisibleElements: false,
  panOnDrag: false,
  panOnScroll: isMac,
  panOnScrollMode: PanOnScrollMode.Free,
  proOptions: proOptions,
  selectNodesOnDrag: true,
  selectionMode: SelectionMode.Full,
  selectionOnDrag: true,
  zoomOnDoubleClick: false,
  zoomOnPinch: true,
  zoomOnScroll: !isMac,
  snapToGrid: true,
  snapGrid: snapGrid,
};
