import {
  default as Tree,
  ItemId,
  moveItemOnTree,
  mutateTree,
  RenderItemParams,
  TreeData,
  TreeDestinationPosition,
  TreeSourcePosition,
} from '@atlaskit/tree';
import { Box, SxProps, Theme } from '@mui/material';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { toTree, TreeNode } from './dataTree';
import { TreeViewItem, TreeViewItemProps } from './tree-view-item/tree-view-item';
import { cloneDeep, isEqual } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { activeWorkspaceVar } from '../../state/state';
import { useExplorerStore } from '../../store/explorer/explorer';
import { useClickOutside } from '@mantine/hooks';
import { useSingleSourceModel } from '../../hooks/use-single-source-model';
import { DeselectAllEntitiesCommand } from '@xspecs/single-source-model';

export type TreeViewProps = {
  nodes?: TreeNode[];
  treeData?: TreeData;
  RootIcon?: ReactNode;
  onMove?: (
    documentId: ItemId,
    destinationParentId: ItemId | null,
    destinationPrecedingId: ItemId | null,
    destinationSucceedingId: ItemId | null,
  ) => Promise<boolean>;
  readOnly?: boolean;
  hideAddButtonForNonFolders?: boolean;
  enableDragOnlyForFolders?: boolean;
  shouldDisableItem?: (item: RenderItemParams['item']) => boolean;
  onShare?: (itemId: ItemId) => void;
  rootSx?: SxProps<Theme>;
  disabledFolderClick?: boolean;
} & Pick<TreeViewItemProps, 'onClick' | 'activeItemId'>;

export const TreeView = (props: TreeViewProps) => {
  const {
    onClick,
    // activeItemId,
    enableDragOnlyForFolders,
    hideAddButtonForNonFolders,
    nodes,
    onMove,
    readOnly,
    shouldDisableItem,
    onShare,
    disabledFolderClick,
    treeData,
  } = props;

  const [tree, setTree] = useState<TreeData | null>(null);
  // const [hasTreeBeenExpandedToActiveItem, nsetHasTreeBeenExpandedToActiveItem] = useState(false);

  const expandedStates = useExplorerStore.use.expandedStates();

  const expandAll = useExplorerStore.use.expandAll();
  const setExpandAll = useExplorerStore.use.setExpandAll();
  const collapseAll = useExplorerStore.use.collapseAll();
  const setCollapseAll = useExplorerStore.use.setCollapseAll();

  const setItemExpandedState = useExplorerStore.use.setItemExpandedState();

  const workspace = useReactiveVar(activeWorkspaceVar);

  const model = useSingleSourceModel();

  const expandedAllRef = useRef<boolean>(false);
  const collapsedAllRef = useRef<boolean>(false);

  const ref = useClickOutside(() => {
    model.messageBus.send(DeselectAllEntitiesCommand, {});
  });

  const onExpand = useCallback(
    (itemId: ItemId) => {
      const isExpanded = true;
      const newTree = mutateTree(tree, itemId, { isExpanded });
      setItemExpandedState(String(itemId), isExpanded);
      setTree(newTree);
    },
    [setItemExpandedState, tree],
  );

  const onCollapse = useCallback(
    (itemId: ItemId) => {
      const isExpanded = false;
      const newTree = mutateTree(tree, itemId, { isExpanded });
      setItemExpandedState(String(itemId), isExpanded);
      setTree(newTree);
    },
    [setItemExpandedState, tree],
  );

  const onDragEnd = useCallback(
    (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
      if (!destination || isEqual(source, destination)) return;
      if (enableDragOnlyForFolders && tree.items[destination.parentId].data.isFolder === false) return;
      setTree(moveItemOnTree(tree, source, destination));
      const destinationParent = tree.items[destination.parentId];
      const documentId = tree.items[source.parentId].children[source.index];
      const destinationParentId = destinationParent.id === workspace.id ? null : destination.parentId;
      const destinationPrecedingId = destinationParent.children[destination.index - 1];
      const destinationSucceedingId = destinationParent.children[destination.index];
      onMove?.(documentId, destinationParentId, destinationPrecedingId, destinationSucceedingId);
    },
    [enableDragOnlyForFolders, tree, workspace, onMove],
  );

  const expandItem = useCallback(
    (itemId: ItemId) => {
      const isExpanded = true;
      const newTree = mutateTree(tree, itemId, { isExpanded });
      setItemExpandedState(String(itemId), isExpanded);
      setTree(newTree);
    },
    [setItemExpandedState, tree],
  );

  const renderItem = useCallback(
    (params: RenderItemParams) => (
      <TreeViewItem
        {...params}
        onClick={onClick}
        hideAddButtonForNonFolders={hideAddButtonForNonFolders}
        readOnly={readOnly}
        expandItem={expandItem}
        shouldDisableItem={shouldDisableItem}
        onShare={onShare}
        disabledFolderClick={disabledFolderClick}
      />
    ),
    [onClick, hideAddButtonForNonFolders, readOnly, expandItem, shouldDisableItem, onShare, disabledFolderClick],
  );

  // const expandTreeToActiveItem = useCallback(() => {
  //   if (hasTreeBeenExpandedToActiveItem) return;
  //   if (activeItemId && tree?.items[activeItemId]) {
  //     const parents = getParents(tree, activeItemId);
  //     let newTree = tree;
  //     parents.forEach((parentId) => {
  //       const isExpanded = true;
  //       newTree = mutateTree(newTree, parentId, { isExpanded });
  //       // updateTreeStateInLocalStorage(parentId, isExpanded);
  //     });
  //     if (!isEqual(tree, newTree)) {
  //       setTree(newTree);
  //       setHasTreeBeenExpandedToActiveItem(true);
  //     }
  //   }
  // }, [activeItemId, hasTreeBeenExpandedToActiveItem, tree]);

  useEffect(() => {
    if (treeData) {
      setTree(treeData);
    } else if (nodes.length > 0) {
      const treeFromNodes = toTree(nodes);
      setTree(treeFromNodes);
    } else {
      setTree(null);
    }
  }, [nodes, treeData]);

  useEffect(() => {
    if (!treeData || expandedAllRef.current || collapsedAllRef.current) {
      expandedAllRef.current = false;
      collapsedAllRef.current = false;
      return;
    }

    const treeDataCopy = cloneDeep(treeData);
    Object.keys(treeDataCopy.items).forEach((key) => {
      if (expandAll) {
        treeDataCopy.items[key].isExpanded = true;
        expandedAllRef.current = true;
      } else if (collapseAll) {
        treeDataCopy.items[key].isExpanded = false;
        collapsedAllRef.current = true;
      } else {
        treeDataCopy.items[key].isExpanded = expandedStates[key] ?? false;
      }
    });

    setTree(treeDataCopy);

    if (expandedAllRef.current) {
      setExpandAll(false);
    }

    if (collapsedAllRef.current) {
      setCollapseAll(false);
    }
  }, [collapseAll, expandAll, expandedStates, setCollapseAll, setExpandAll, treeData]);

  return (
    <Box sx={{ height: '100%', overflowY: 'auto' }} ref={ref}>
      {tree ? (
        <Tree
          tree={tree}
          renderItem={renderItem}
          onExpand={onExpand}
          onCollapse={onCollapse}
          onDragEnd={onDragEnd}
          isDragEnabled={!readOnly}
          isNestingEnabled
        />
      ) : null}
    </Box>
  );
};
