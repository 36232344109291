import { SearchResultType } from '../../../../gql/graphql';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { Checkbox, MenuItem, Typography } from '@mui/material';
import { ChangesetIconPlain, DocIconPlain, SpecIconPlain } from '@xspecs/ui-assets';
import { useNavigate } from 'react-router-dom';
import { getRoutePathFromParams, RoutePaths } from '../../../../config/route-paths/route-paths';
import { useReactiveVar } from '@apollo/client';
import { activeWorkspaceVar, activeOrganizationVar, showSearchVar } from '../../../../state/state';
import { SearchFile } from '../../../../state/types';

export type SelectedFile = {
  id: string;
  type: SearchResultType;
  title: string;
};

export type FileListItemProps = {
  isSelectable?: boolean;
  onSelectedChange?: (file: SelectedFile, selected: boolean) => void;
  selectedFiles?: SelectedFile[];
} & SearchFile;

export const FileListItem = (props: FileListItemProps) => {
  const { id, fileType, path, title, isSelectable, onSelectedChange, selectedFiles } = props;

  const selected = useMemo(() => Boolean(selectedFiles?.find((f) => f.id === id)), [id, selectedFiles]);

  const activeOrganization = useReactiveVar(activeOrganizationVar);
  const activeWorkspace = useReactiveVar(activeWorkspaceVar);

  const navigate = useNavigate();

  const resolveIcon = useCallback(() => {
    switch (fileType) {
      case SearchResultType.Doc:
        return <DocIconPlain data-testid="file-list-item-doc-icon" />;
      case SearchResultType.ComponentSpec:
      case SearchResultType.BusinessRuleSpec:
        return <SpecIconPlain data-testid="file-list-item-spec-icon" />;
      case SearchResultType.Changeset:
        return <ChangesetIconPlain data-testid="file-list-item-changeset-icon" />;
    }
  }, [fileType]);

  const navigateToRoute = useCallback(() => {
    let route = '';
    switch (fileType) {
      case SearchResultType.Doc:
      case SearchResultType.ComponentSpec:
      case SearchResultType.BusinessRuleSpec:
      case SearchResultType.Folder:
        route = getRoutePathFromParams(RoutePaths.Entity, {
          organizationName: activeOrganization.name,
          workspaceName: activeWorkspace.name,
          fileId: id,
        });
        break;
    }
    showSearchVar(false);
    navigate(route);
  }, [activeWorkspace?.name, activeOrganization?.name, fileType, id, navigate]);

  const onClick = useCallback(() => {
    if (isSelectable) onSelectedChange?.({ id, type: fileType, title }, !selected);
    else navigateToRoute();
  }, [fileType, id, isSelectable, navigateToRoute, onSelectedChange, selected, title]);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      onSelectedChange?.({ id, type: fileType, title }, checked);
    },
    [fileType, id, onSelectedChange, title],
  );

  return (
    <MenuItem
      data-testid="file-list-item"
      sx={{ display: 'flex', gap: '2px', px: 0, alignItems: 'center' }}
      onClick={onClick}
    >
      {isSelectable ? <Checkbox sx={{ py: '2px', pr: 1 }} size="small" checked={selected} onChange={onChange} /> : null}
      {resolveIcon()}
      <Typography variant="body1" sx={{ ml: '6px' }}>
        {title || 'Untitled'}
      </Typography>
      <Typography color="text.secondary" variant="body1">
        {`- ${path}`}
      </Typography>
    </MenuItem>
  );
};
