import { Avatars } from '../../../avatars/avatars';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { activeOrganizationVar } from '../../../../state/state';
import { useReactiveVar } from '@apollo/client';

export const SingleSourceModelToolbarAvatars = () => {
  const organization = useReactiveVar(activeOrganizationVar);
  const activeUsers = useSingleSourceStore.use.activeUsersByFile()[organization.singleSourceModel.id] ?? [];

  return <Avatars users={activeUsers} />;
};
