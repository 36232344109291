import { Avatar, Stack, Typography } from '@mui/material';
import { formatDistance, isBefore } from 'date-fns';
import { useIntl } from 'react-intl';
import { useQuery, useReactiveVar } from '@apollo/client';
import { activeOrganizationVar } from '../../state/state';
import { USERS_QUERY } from '../../graphql/queries';
import { useMemo } from 'react';

type CommentUserInfoProps = {
  userId: string;
  createdAt: Date;
  updatedAt?: Date;
};

export const CommentUserInfo = (props: CommentUserInfoProps) => {
  const { userId, updatedAt, createdAt } = props;

  const { formatMessage: f } = useIntl();

  const organization = useReactiveVar(activeOrganizationVar);

  const { data: usersData } = useQuery(USERS_QUERY, {
    variables: { organizationId: organization?.id },
    skip: !organization?.id,
  });

  const user = useMemo(() => usersData?.users.find((user) => user.id === userId), [userId, usersData?.users]);

  const dateToUse = isBefore(createdAt, updatedAt) ? updatedAt : createdAt;

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Avatar sx={{ width: 24, height: 24 }} src={user?.picture} alt={user?.name} />
      <Stack>
        <Typography variant="subtitle2">{user?.name ?? f({ id: 'unknown-user' })}</Typography>
        <Typography variant="tooltip">{formatDistance(dateToUse, Date.now())} ago</Typography>
      </Stack>
    </Stack>
  );
};
