import { useCallback, useMemo } from 'react';
import { useAuth } from '../auth';
import { useSingleSourceModel } from './use-single-source-model';
import { useReactiveVar } from '@apollo/client';
import { activeOrganizationVar, activeWorkspaceVar } from '../state/state';
import { Analytics, AppTypeEvent, NodeTypeEvent } from '@xspecs/single-source-model';

export { AppTypeEvent, NodeTypeEvent } from '@xspecs/single-source-model';

export const useTrackEvents = () => {
  const model = useSingleSourceModel();
  const organization = useReactiveVar(activeOrganizationVar);
  const workspace = useReactiveVar(activeWorkspaceVar);

  const analytics = useMemo(
    () => Analytics.setInstance(model, window, organization, workspace),
    [model, organization, workspace],
  );

  const trackPageView = useCallback(
    (path: string, title: string, pageType: string) => analytics.trackPageView(path, title, pageType),
    [analytics],
  );

  const trackNodeOperation = useCallback(
    (eventType: NodeTypeEvent, constructType: string) => analytics.trackNodeOperation(eventType, constructType),
    [analytics],
  );

  const trackNodeOperationByNodeId = useCallback(
    (eventType: NodeTypeEvent, constructId: string) => analytics.trackNodeOperationByNodeId(eventType, constructId),
    [analytics],
  );

  const trackEvent = useCallback((event: AppTypeEvent, data: any) => analytics.trackEvent(event, data), [analytics]);

  return {
    trackEvent,
    trackNodeOperation,
    trackNodeOperationByNodeId,
    trackPageView,
    useAuth,
  } as const;
};
