import { Label } from '@xspecs/single-source-model';
import { ShowDetailsButton } from '../../../show-details-button/show-details-button';
import { FloatingMenuLabelsDetailsButton } from '../floating/menu/labels-details-button/floating-menu-labels-details-button';
import { CSSProperties, forwardRef, memo, RefObject, useCallback, useRef, useState } from 'react';
import { Box, Stack, styled } from '@mui/material';
import {
  arrow,
  autoPlacement,
  FloatingArrow,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { CopyLinkButton } from 'presentation/client/src/components/copy-link-button/copy-link-button';
import { useCopyEntityLink } from '../hooks/use-copy-entity-link';

type FloatingMenuProps = {
  id: string;
  styles: CSSProperties;
  arrowRef: RefObject<SVGSVGElement>;
  context: any;
  type: string;
  labels: Label[];
  onManageLabels: () => void;
  disableLabels?: boolean;
  name: string;
};

const _FloatingMenu = forwardRef<HTMLDivElement, FloatingMenuProps>((props, ref) => {
  const { id, styles, arrowRef, context, type, labels, onManageLabels, disableLabels, name, ...rest } = props;
  const { onCopyEntityLink } = useCopyEntityLink();

  const copyEntityLink = useCallback(async () => {
    await onCopyEntityLink(id, type, name);
    onManageLabels();
  }, [onCopyEntityLink, id, type, name, onManageLabels]);

  return (
    <FloatingContent {...rest} ref={ref} sx={styles}>
      <FloatingArrow ref={arrowRef} context={context} fill="white" />
      <Stack gap={0.25} alignItems="center">
        <ShowDetailsButton id={id} type={type} />
        {!disableLabels ? (
          <FloatingMenuLabelsDetailsButton entityId={id} selectedLabels={labels} onManageLabels={onManageLabels} />
        ) : null}
        <CopyLinkButton onClick={copyEntityLink} />
      </Stack>
    </FloatingContent>
  );
});
_FloatingMenu.displayName = 'FloatingMenu';
export const FloatingMenu = memo(_FloatingMenu);

const FloatingContent = styled(Box)(({ theme }) => ({
  padding: '2px 4px',
  borderRadius: 8,
  background: theme.palette.background.paper,
  filter:
    'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.20))',
  zIndex: 'calc(infinity)',
}));

export const useFloatingMenu = () => {
  const [showFloating, setShowFloating] = useState(false);
  const arrowRef = useRef<SVGSVGElement>(null);
  const { floatingStyles, refs, context } = useFloating({
    placement: 'right',
    middleware: [offset(20), autoPlacement({ allowedPlacements: ['right'] }), arrow({ element: arrowRef.current })],
    open: showFloating,
    onOpenChange: setShowFloating,
  });
  const click = useClick(context, { keyboardHandlers: false, toggle: false });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);
  const onManageLabels = useCallback(() => setShowFloating(false), []);

  return {
    showFloating,
    floatingStyles,
    context,
    arrowRef,
    referenceProps: getReferenceProps(),
    floatingProps: getFloatingProps(),
    onManageLabels,
    referenceRef: refs.setReference,
    floatingRef: refs.setFloating,
  };
};
