import { IconButton, Stack, Tooltip } from '@mui/material';
import { CheckRounded, CloseRounded, OpenInBrowserRounded } from '@mui/icons-material';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSingleSourceModel } from '../../../../../../hooks/use-single-source-model';
import { useMutation, useReactiveVar } from '@apollo/client';
import { RESOLVE_THREAD_MUTATION } from '../../../../../../graphql/mutations';
import { activeWorkspaceVar } from '../../../../../../state/state';
import { AppTypeEvent, useTrackEvents } from '../../../../../../hooks/use-track-events';

type ThreadHeaderProps = {
  threadId: string;
  onClose: () => void;
  disableResolve?: boolean;
  isResolved: boolean;
};

export const ThreadHeader = (props: ThreadHeaderProps) => {
  const { threadId, onClose, disableResolve, isResolved } = props;

  const { formatMessage: f } = useIntl();

  const [resolveThread] = useMutation(RESOLVE_THREAD_MUTATION);

  const workspace = useReactiveVar(activeWorkspaceVar);

  const { trackEvent } = useTrackEvents();

  const model = useSingleSourceModel();

  const onResolve = useCallback(async () => {
    if (isResolved) {
      model.threadInteractor.unresolvedThread(threadId);
      trackEvent(AppTypeEvent.CommentThreadUnResolved, { threadId: threadId });
    } else {
      model.threadInteractor.resolveThread(threadId);
      await resolveThread({ variables: { args: { id: threadId, workspaceId: workspace.id } } });
      trackEvent(AppTypeEvent.CommentThreadResolved, { threadId: threadId });
    }
  }, [isResolved, model.threadInteractor, threadId, trackEvent, resolveThread, workspace.id]);

  const Icon = isResolved ? OpenInBrowserRounded : CheckRounded;

  return (
    <Stack width="100%" height={24} direction="row" gap={0.5} alignItems="center" justifyContent="flex-end">
      {!disableResolve ? (
        <Tooltip title={f({ id: isResolved ? 'unresolve' : 'resolve' })}>
          <IconButton size="small" sx={buttonSx} onClick={onResolve}>
            <Icon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      ) : null}
      <Tooltip title={f({ id: 'close' })}>
        <IconButton size="small" sx={buttonSx} onClick={onClose}>
          <CloseRounded fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

const buttonSx = { fontSize: '24px', p: 0 };
