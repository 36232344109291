import { MentionCombobox } from '../plate-ui/mention-combobox';
import React, { useMemo } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { USERS_QUERY } from '../../graphql/queries';
import { activeOrganizationVar } from '../../state/state';

export const MentionUserCombobox = () => {
  const organization = useReactiveVar(activeOrganizationVar);

  const { data } = useQuery(USERS_QUERY, { variables: { organizationId: organization?.id }, skip: !organization });

  const mentionables = useMemo(() => data?.users.map((user) => ({ key: user.id, text: user.name })), [data?.users]);

  return <MentionCombobox items={mentionables} />;
};
