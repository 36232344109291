import { useReactiveVar } from '@apollo/client';
import { activeWorkspaceVar, showSearchVar } from '../../state/state';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { Typography } from '@mui/material';
import { Modal } from '../modal/modal';
import { SearchFiles } from '../search-files/search-files';

export const SearchModal = () => {
  const isOpen = useReactiveVar(showSearchVar);
  const activeWorkspace = useReactiveVar(activeWorkspaceVar);

  const { formatMessage: f } = useIntl();

  const onClose = useCallback(() => {
    showSearchVar(false);
  }, []);

  return (
    <Modal
      id="SearchModal"
      isOpen={isOpen}
      onClose={onClose}
      rootSxOverrides={{ width: { xs: '100%', md: 800 }, height: { xs: '100%', md: 559 } }}
      headerSxOverrides={{ justifyContent: 'space-between' }}
      headerStartContent={
        <Typography variant="h6">{f({ id: 'search-workspace' }, { name: activeWorkspace?.name })}</Typography>
      }
    >
      <SearchFiles autoFocus />
    </Modal>
  );
};
